.appContent{

  margin: 0 auto;
  max-width: 987px;
}

@media screen and (max-width: 480px) {

  .appContent{
    max-width: 480px;
    margin: 0 5px;
  }
  
}

.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: white;
  margin-bottom: 1em;
  width: 100%;
}

.navBar ul {
  list-style-type: none;
  display: flex;
  gap: 1rem;
}

.navBar li {
  margin: 0;

  padding: 0;
}

.navBar a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.navBar a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}


.searchByName{
  width: 100%;
  height: 3em;

}

.SearchUI{
  margin: 0 10%;
}